@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

.progress::-webkit-progress-value {
  transition: width 0.5s ease;
}

.progress {
  transition: width 0.5s ease;
}

progress {
  transition: value 0.3s ease-in-out !important;
}

.skeleton {
  @apply bg-base-content/10;
}

.text-base-content-2 {
    @apply text-base-content/80;
}
